<template>
  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ $t('ENTRIES') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            variant="primary"
            :to="{ name: 'company-configuration'}"
          >
            <span> {{ $t('ADD_COMPANY') }} </span>
            <feather-icon icon="PlusCircleIcon" />
          </b-button>
          <b-badge
            v-if="onboardedFilter"
            pill
            variant="light-info"
            class="text-capitalize ml-2"
          >
            {{ $t('ONBOARDED') }}
          </b-badge>
        </b-col>
        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="text-nowrap">
            <b-dropdown
              variant="link"
              no-caret
              class="chart-dropdown"
              toggle-class="p-0"
            >
              <template #button-content>
                <feather-icon
                  size="17"
                  icon="MoreVerticalIcon"
                  class="text-body cursor-pointer"
                />
              </template>
              <b-dropdown-item
                v-model="onboardedFilter"
                @click="setOnboarded()"
              >
                {{ $t('ALL') }}
              </b-dropdown-item>
              <b-dropdown-item
                v-model="onboardedFilter"
                @click="setOnboarded(true)"
              >
                {{ $t('ONBOARDED') }}
              </b-dropdown-item>
            </b-dropdown>
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('SEARCH')"
            />
          </div>
        </b-col>
      </b-row>

    </div>
    <b-table
      ref="refCompanyListTable"
      :items="bindCompanies"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #head(invoiceStatus)>
        <feather-icon
          icon="TrendingUpIcon"
          class="mx-auto"
        />
      </template>

      <!-- Column: Company Name -->
      <template #cell(Name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              :id="`company-row-${data.item.id}`"
              size="32"
              variant="light-success"
              :to="{ name: 'company-view', params: { id: data.item.id } }"
            >
              <feather-icon
                icon="LayoutIcon"
              />
            </b-avatar>
          </template>
          <b-link
            :to="{ name: 'company-view', params: { id: data.item.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.name }}
          </b-link>
        </b-media>
      </template>

      <!-- Column: Address -->
      <template #cell(Address)="data">
        <div class="text-nowrap">
          <feather-icon
            v-if="data.item.addressLine1"
            icon="MapPinIcon"
            size="18"
            class="mr-50 text-primary"
          />
          <span class="align-text-top text-capitalize">{{ data.item.addressLine1 }} {{ data.item.addressLine2 }}</span>
        </div>
      </template>

      <!-- Column: Post Code -->
      <template #cell(PostCode)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.postcode }}
          </span>
        </b-media>
      </template>

      <!-- Column: City -->
      <template #cell(City)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.city }}
          </span>
        </b-media>
      </template>

      <!-- Column: State -->
      <template #cell(CreateAt)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ formatDate(data.item.created) }}
          </span>
        </b-media>
      </template>
      <!-- Column: Package -->
      <template #cell(Package)="data">
        <b-badge
          pill
          :variant="`light-${mapPackageToVariant(data.item.packageId).variant}`"
          class="text-capitalize"
        >
          {{ mapPackageToVariant(data.item.packageId).name }}
        </b-badge>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <feather-icon
            :id="`company-row-${data.item.id}-send-icon`"
            icon="EditIcon"
            class="cursor-pointer"
            size="16"
            @click="$router.push({ name: 'company-configuration-id', params: { id: data.item.id }})"
          />
          <b-tooltip
            title="Edit Company"
            class="cursor-pointer"
            :target="`company-row-${data.item.id}-send-icon`"
          />

          <feather-icon
            :id="`company-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1 cursor-pointer"
            @click="$router.push({ name: 'company-view', params: { id: data.item.id }})"
          />
          <b-tooltip
            title="Preview Company"
            :target="`company-row-${data.item.id}-preview-icon`"
          />

        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('SHOWING') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('ENTRIES') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalCompanies"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BLink,
  BAvatar,
  BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// eslint-disable-next-line no-unused-vars
import { mapActions } from 'vuex'
import { ref, computed } from '@vue/composition-api'
import constants from '@/constants/static.json'
// eslint-disable-next-line no-unused-vars
import { mapPackageToVariant, paginateArray } from '@/constants/utils'
import { mixinDate } from '@/constants/mixinDate'
// eslint-disable-next-line no-unused-vars
import mixinAlert from '@/constants/mixinAlert'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BPagination,
    BTooltip,
    vSelect,
    BLink,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
  },
  mixins: [mixinDate],
  data() {
    return {
      searchQuery: '',
      onboardedFilter: '',
      allCompanies: [],
      bindCompanies: [],
    }
  },
  setup() {
    const tableColumns = [
      { key: 'Name', sortable: false },
      { key: 'Address', sortable: false },
      { key: 'PostCode', sortable: false },
      { key: 'City', sortable: false },
      { key: 'Package', sortable: false },
      { key: 'Actions' },
    ]

    const { PER_PAGE_OPTIONS, PER_PAGE } = constants
    const refCompanyListTable = ref(null)

    const perPage = ref(PER_PAGE)
    const totalCompanies = ref(0)
    const currentPage = ref(1)
    const perPageOptions = PER_PAGE_OPTIONS
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)

    const dataMeta = computed(() => {
      const localItemsCount = refCompanyListTable.value ? refCompanyListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalCompanies.value,
      }
    })

    const refetchData = () => {
      refCompanyListTable.value.refresh()
    }

    const {
      successMessage,
      errorMessage,
      showErrorMessage,
      warningMessage,
    } = mixinAlert()

    return {
      tableColumns,
      perPage,
      currentPage,
      sortBy,
      isSortDirDesc,
      totalCompanies,
      dataMeta,
      perPageOptions,
      refCompanyListTable,
      refetchData,
      successMessage,
      errorMessage,
      showErrorMessage,
      warningMessage,
      mapPackageToVariant,
    }
  },
  watch: {
    currentPage: 'fetchCompanies',
    perPage: 'fetchCompanies',
    searchQuery: 'fetchCompanies',
    onboardedFilter: 'fetchCompanies',
  },
  created() {
    this.fetchCompanies()
  },
  methods: {
    ...mapActions('company', ['fetchAllCompanies', 'deleteCompany']),
    setOnboarded(value) {
      this.onboardedFilter = value
    },
    fetchCompanies() {
      if (this.allCompanies.length === 0) {
        try {
          this.fetchAllCompanies().then(response => {
            this.allCompanies = response.data
            this.totalCompanies = this.allCompanies.length
            this.bindCompanies = paginateArray(this.allCompanies, this.perPage, 1)
          })
        } catch (error) {
          this.showErrorMessage()
        }
      } else if (this.searchQuery !== '' || this.onboardedFilter !== undefined) {
        const filterData = this.filteredData()
        this.totalCompanies = filterData.length
        this.bindCompanies = paginateArray(filterData, this.perPage, this.currentPage)
      } else {
        this.totalCompanies = this.allCompanies.length
        this.bindCompanies = paginateArray(this.allCompanies, this.perPage, this.currentPage)
      }
    },
    filteredData() {
      const queryLowered = this.searchQuery.toLowerCase()
      return this.allCompanies.filter(company => {
        const matchesName = company.name.toLowerCase().includes(queryLowered)
        const matchesOnboarded = this.onboardedFilter ? company.onboardedFilter === this.onboardedFilter : true
        return (this.searchQuery === '' || matchesName) && matchesOnboarded
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
